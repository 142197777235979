/* Searchbar - Inputtext Component */
.searchBar .p-inputtext.p-inputtext-lg {
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
  border-bottom-left-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
  height: 78px;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 30px;
  padding-bottom: 30px;
}

.searchBar .p-input-icon-left > i:first-of-type {
  padding-left: 1.5rem;
}

.searchBar .p-input-icon-right > i:last-of-type {
  padding-right: 1.5rem;
}

.searchBar .p-input-icon-right > i.pi-spinner {
  padding-right: 0;
  font-size: 2em;
  margin-top: -1rem;
  margin-right: 1rem !important;
  color: var(--primary-color);
}

/* Panel Component */
.p-panel .p-panel-content {
  border: 20px solid white;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  padding: 0;
}

.createModal .p-dialog-title,
.editModal .p-dialog-title {
  font-size: 1.25em !important;
}

.createModal .p-toolbar,
.editModal .p-toolbar {
  border: 0 !important;
  background-color: transparent !important;
}

.ve-treenode-musthave.p-highlight {
  background-color: var(--gray-100) !important;
  color: var(--gray-800) !important;
}

.ve-treenode-musthave.p-highlight .p-checkbox-box {
  border-color: var(--gray-400) !important;
  background: var(--gray-400) !important;
}

.ve-treetablelist .p-treetable-thead {
  display: none;
}

/* Steps */
.p-steps {
  .p-steps-item.p-highlight .p-steps-number {
    background-color: var(--primary-color) !important;
    color: white !important;
  }
  .p-steps-item.p-highlight .p-steps-title {
    color: var(--primary-color) !important;
  }
}

@media screen and (max-width: 720px) {
  .p-steps {
    height: 200px;
  }
  .p-steps > ul {
    flex-direction: column;
    height: 100%;
  }
  .p-steps > ul .p-steps-item {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: center;
  }
  .p-steps > ul .p-steps-item:before {
    position: static;
    left: auto;
    top: auto;
    margin-top: 0;
    border-left: 1px solid var(--surface-d);
    border-top: 0 none;
    width: auto;
    height: 100%;
    margin-left: 1rem;
  }
  .p-steps > ul .p-steps-item .p-menuitem-link {
    flex-direction: row;
    overflow: visible;
  }
  .p-steps > ul .p-steps-item .p-menuitem-link .p-steps-title {
    margin: 0 0.5rem 0;
  }
  .p-steps > ul .p-steps-item:last-child {
    flex-grow: 0;
  }
  .p-steps > ul .p-steps-item:last-child .p-menuitem-link {
    padding: 0;
  }
  .p-steps > ul .p-steps-item:last-child:before {
    display: none;
  }
}
