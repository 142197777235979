.main [class~='p-menuitem-link'] {
  background-color: transparent !important;
}

.main [class~='p-toolbar'] {
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
}
