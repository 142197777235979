.list > [class~='p-toolbar'] {
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
}

.list {
  margin-bottom: 40px;
}

.list > [class~='p-card'] {
  box-shadow: none;
  border-radius: 12px;
}

.list [class~='p-card-body'] {
  padding-bottom: 0 !important;
}

.list [class~='p-paginator-bottom'] {
  /* Paginator */
  border-width: 0 !important;
  margin-top: 15px;
}

.column {
  a {
    color: var(--primary-color);
    text-decoration: none;
  }
}

.first-column {
  width: 15%;
  min-width: 200px;
  border-right: 1px solid var(--surface-border) !important;
  font-family: 'Roboto-Bold', 'sans-serif';
  a {
    color: var(--primary-color);
    text-decoration: none;
  }
}

.email-column {
  a {
    color: var(--primary-color);
    text-decoration: underline;
  }
}

.action-column {
  width: 15%;
  min-width: 200px;
  font-family: 'Roboto-Bold', 'sans-serif';
}
