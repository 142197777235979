.favorites {
  box-shadow: none !important;
  border-radius: 12px !important;
  border: 5px solid var(--surface-200) !important; // --surface-surface
  min-height: 350px !important;

  & > [class='p-card-header'] {
    text-align: right;
  }

  & > [class~='p-card-body'] {
    padding: 0 1.25rem 0 1.25rem !important;
  }

  & [class='p-card-content'] {
    padding-top: 0 !important;
  }

  & [class~='p-card-title'] {
    font-size: 1rem !important;
    font-weight: normal !important;
    font-family: 'Roboto', 'sans-serif';
    margin-bottom: 0.5rem !important;
    color: #495057 !important;
  }

  & [class~='p-card-subtitle'] {
    font-size: 1.5rem !important;
    font-family: 'Roboto-Bold', 'sans-serif' !important;
    margin-bottom: 0.5rem !important;
    color: #495057 !important;
  }
}

.dataview > [class~='p-toolbar'] {
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
}

.dataview [class~='card'] {
  box-shadow: none;
  border-radius: 12px;
  border: 5px solid var(--surface-200); // --surface-surface
  min-height: 300px;
}

.dataview > [class~='p-paginator'] {
  border: 5px solid var(--surface-200) !important; // --surface-surface
}

.dataview [class~='p-dataview-content'] {
  background: none !important;
}

.topfive {
  margin-bottom: 40px;
}
