@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
  src:
    local('Roboto'),
    local('Roboto-Regular'),
    url('../fonts/Roboto-Regular.woff2') format('woff2'),
    url('../fonts/Roboto-Regular.woff') format('woff'),
    url('../fonts/Roboto-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto-Bold';
  font-weight: normal;
  font-style: normal;
  src:
    local('RobotoBold'),
    local('Roboto-Bold'),
    url('../fonts/Roboto-Bold.woff2') format('woff2'),
    url('../fonts/Roboto-Bold.woff') format('woff'),
    url('../fonts/Roboto-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto Mono', monospace;
  font-weight: normal;
  font-style: normal;
  src:
    local('RobotoMono'),
    local('Roboto-Mono'),
    url('../fonts/RobotoMono-Regular.ttf') format('ttf');
}

body {
  margin: 0;
  font-family: 'Roboto', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

#userMenu {
  width: 250px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.mainContent {
  max-width: 1440px;
}

.bg-black {
  background-color: #000000;
}

h1 {
  font-size: 1.5em;
  font-weight: normal !important;
  font-family: 'Roboto-Bold', 'sans-serif';

  .pre {
    font-size: inherit;
  }
}

h2 {
  font-size: 1.25em;
  font-weight: normal !important;
  font-family: 'Roboto-Bold', 'sans-serif';
}

h3 {
  font-size: 1.125em;
  color: #495057 !important;
  font-weight: normal !important;
}

.ve-table-of-contents {
  line-height: 1em;
  ul {
    list-style: none;
    padding-left: 0px;
    li {
      a {
        color: inherit;
        text-decoration: none;
        :hover {
          font-family: 'Roboto', 'sans-serif';
          color: var(--primary-color);
        }
      }
    }
  }
  h1 {
    font-size: 1.125em;
    font-family: 'Roboto-Bold', 'sans-serif';
  }
  h2 {
    font-size: medium;
    font-family: 'Roboto-Bold', 'sans-serif';
    font-weight: normal !important;
  }
  h3 {
    font-size: medium;
    font-family: 'Roboto', 'sans-serif';
    font-weight: normal !important;
  }
}

.ve-toolbar {
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
}
ul .ve-markdown-container ul {
  list-style-type: disc;
}
.ve-markdown-container {
  table {
    width: 100%;
    border-collapse: collapse;
    th,
    td {
      border: 1px solid var(--gray-300);
      padding: 0.3em;
    }
  }
  a {
    color: var(--primary-color);
    text-decoration: none;
  }
  p {
    line-height: 1.5rem;
  }
  ul,
  ol {
    padding-left: 10em;
  }
  li {
    margin-bottom: 5px;
  }
  :first-child {
    margin-block-start: 0px;
  }
  :last-child {
    margin-block-end: 0px;
  }
}

.ve-file-list {
  ul {
    padding-left: 1em;
  }
  a {
    color: var(--primary-color);
    text-decoration: none;
  }
}
